// Generated by Framer (a4439fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/z4o8pacIc";

const enabledGestures = {V0og6JA1U: {hover: true}};

const cycleOrder = ["V0og6JA1U", "bixSFsPl6"];

const serializationHash = "framer-gGiEa"

const variantClassNames = {bixSFsPl6: "framer-v-m2yhvh", V0og6JA1U: "framer-v-1pgm9oj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Large: "V0og6JA1U", Small: "bixSFsPl6"}

const getProps = ({height, id, link, newTab, time, width, ...props}) => { return {...props, LmVtFlXQI: time ?? props.LmVtFlXQI ?? "60m", RmCa5FCML: link ?? props.RmCa5FCML, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "V0og6JA1U", VGAsIj70x: newTab ?? props.VGAsIj70x} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;time?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, LmVtFlXQI, RmCa5FCML, VGAsIj70x, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "V0og6JA1U", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={RmCa5FCML} openInNewTab={VGAsIj70x}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1pgm9oj", className, classNames)} framer-1v2i3jh`} data-framer-name={"Large"} layoutDependency={layoutDependency} layoutId={"V0og6JA1U"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-fa22f986-965c-4857-a55b-4df137003671, rgb(36, 36, 36))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} variants={{"V0og6JA1U-hover": {backgroundColor: "var(--token-08bf2c81-c18a-419a-8fcb-91ee27a26c81, rgb(65, 65, 65))"}}} {...addPropertyOverrides({"V0og6JA1U-hover": {"data-framer-name": undefined}, bixSFsPl6: {"data-framer-name": "Small"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1le8fvo"} data-framer-name={"Title"} layoutDependency={layoutDependency} layoutId={"pO5LjWqGe"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-h6oxpg"} data-styles-preset={"z4o8pacIc"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-39e2b79f-dbe5-4d7a-a7e7-9459498c8818, rgb(0, 0, 0)))"}}>60m</motion.p></React.Fragment>} className={"framer-djuths"} data-framer-name={"15m"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"kYPYaZZPH"} style={{"--extracted-r6o4lv": "var(--token-39e2b79f-dbe5-4d7a-a7e7-9459498c8818, rgb(0, 0, 0))"}} text={LmVtFlXQI} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gGiEa.framer-1v2i3jh, .framer-gGiEa .framer-1v2i3jh { display: block; }", ".framer-gGiEa.framer-1pgm9oj { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: visible; padding: 0px 24px 0px 24px; position: relative; text-decoration: none; width: min-content; }", ".framer-gGiEa .framer-1le8fvo { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 3px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-gGiEa .framer-djuths { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gGiEa.framer-1pgm9oj, .framer-gGiEa .framer-1le8fvo { gap: 0px; } .framer-gGiEa.framer-1pgm9oj > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-gGiEa.framer-1pgm9oj > :first-child { margin-top: 0px; } .framer-gGiEa.framer-1pgm9oj > :last-child { margin-bottom: 0px; } .framer-gGiEa .framer-1le8fvo > * { margin: 0px; margin-left: calc(3px / 2); margin-right: calc(3px / 2); } .framer-gGiEa .framer-1le8fvo > :first-child { margin-left: 0px; } .framer-gGiEa .framer-1le8fvo > :last-child { margin-right: 0px; } }", ".framer-gGiEa.framer-v-m2yhvh.framer-1pgm9oj { cursor: unset; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 78
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"bixSFsPl6":{"layout":["auto","fixed"]},"z8zJmSrrW":{"layout":["auto","fixed"]}}}
 * @framerVariables {"LmVtFlXQI":"time","RmCa5FCML":"link","VGAsIj70x":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpElVjyT7G: React.ComponentType<Props> = withCSS(Component, css, "framer-gGiEa") as typeof Component;
export default FramerpElVjyT7G;

FramerpElVjyT7G.displayName = "UI — Button | Booking";

FramerpElVjyT7G.defaultProps = {height: 32, width: 78};

addPropertyControls(FramerpElVjyT7G, {variant: {options: ["V0og6JA1U", "bixSFsPl6"], optionTitles: ["Large", "Small"], title: "Variant", type: ControlType.Enum}, LmVtFlXQI: {defaultValue: "60m", displayTextArea: false, placeholder: "Time", title: "Time", type: ControlType.String}, RmCa5FCML: {title: "Link", type: ControlType.Link}, VGAsIj70x: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerpElVjyT7G, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})